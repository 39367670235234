import { Image } from 'antd';

const HomeBanner = () => {
  return (
    <>
      <Image preview={false} src="./images/banner1.jpg" />
    </>
  );
};

export default HomeBanner;
